import React from 'react';
import TextField from '@material-ui/core/TextField';
import type {RenderTextFieldProps} from './types/TextField';

const renderTextField: React.FC<RenderTextFieldProps> = ({
    label,
    placeholder,
    input,
    meta: {invalid, error, warning},
    inputProps,
    ...custom
}) => (
    <TextField
        label={label}
        placeholder={placeholder}
        error={invalid || Boolean(error) || Boolean(warning)}
        {...input}
        {...custom}
        helperText={(invalid && error) || warning || custom.helperText}
    />
);

export default renderTextField;
